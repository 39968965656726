import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Md4Card from "../components/md4card"
import Jumbotron from "react-bootstrap/Jumbotron"

export const data = graphql`
  query {
    allBlogDataset(
      filter: {
        Category: {
          nin: [
            "IELTS"
            "RAS"
            "RRB Exam"
            "Artificial Intelligence"
            "Cloud Computing"
            "Cybersecurity"
          ]
        }
      }
    ) {
      distinct(field: Category)
      nodes {
        Title
        SlugURL
        Category
        Excerpt
        FeaturedImageURL
      }
    }
  }
`
export const seo = {
  title: "Blogs | Crampete",
  description:
    "In-depth blog articles on up-skilling, placement preparation, emerging tech skills like data science, digital marketing, full stack web development and more. Read on...",
  keywords: "Full Stack development, Data Science, Digital Marketing",
  image: "",
  url: "https://www.crampete.com/blogs",
  author: "Crampete",
}

function move(arr, old_index, new_index) {
  while (old_index < 0) {
      old_index += arr.length;
  }
  while (new_index < 0) {
      new_index += arr.length;
  }
  if (new_index >= arr.length) {
      var k = new_index - arr.length;
      while ((k--) + 1) {
          arr.push(undefined);
      }
  }
   arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);  
 return arr;
}

const Blogcategory = ({ data }) => {

  return (
    <Layout
      title={seo.title}
      description={seo.description}
      image={seo.image}
      keywords={seo.keywords}
      url={seo.url}
      author={seo.author}
    >
      <Jumbotron className="bg-lightblue">
        <div class="container">
          <div
            style={{ maxWidth: "600px", textAlign: "center", margin: "auto" }}
          >
            <h2>Blogs</h2>
            <p className="mt-4">
              Keep up with latest and greatest developments in up-skilling with
              our handcrafted articles, carefully put together for you.
            </p>
          </div>
        </div>
      </Jumbotron>
      <div class="container">
        <div>
          {move(data.allBlogDataset.distinct,2,0).map(function(val) {
            const filterCategory = data.allBlogDataset.nodes.filter(abc => {
              return abc.Category === val
            })
            
            const renderOnline = filterCategory
              .map(i => (
                <Md4Card
                  title={i.Title}
                  excerpt={i.Excerpt}
                  slugURL={i.SlugURL}
                  featuredImageURL={i.FeaturedImageURL}
                />
              ))
              .slice(0, 4)
            
              return (
              <div>
                <div className="row">
                  <div className="col-6">
                    <h2 className="mb-3">{val}</h2>
                  </div>
                  <div className="col-6 mt-3 text-right">
                    <a
                      href={
                        "/category/" +
                        val
                          .toLowerCase()
                          .split(" ")
                          .join("-")
                      }
                    >
                      View More
                    </a>
                  </div>
                </div>

                <div class="row mb-5">{renderOnline}</div>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default Blogcategory